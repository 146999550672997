export const keywordMapping = {
  연락처: "Contact",
  Contact: "Contact",
  contact: "Contact",
  Introduce: "Introduce",
  소개: "Introduce",
  introduce: "Introduce",
  "Personal Project": "PersonalProjects",
  개인프로젝트: "PersonalProjects",
  "personal project": "PersonalProjects",
  "개인 프로젝트": "PersonalProjects",
  PersonalProject: "PersonalProjects",
  personalproject: "PersonalProjects",
  "Team Project": "TeamProjects",
  팀프로젝트: "TeamProjects",
  "team project": "TeamProjects",
  "팀 프로젝트": "TeamProjects",
  TeamProject: "TeamProjects",
  teamproject: "TeamProjects",
  Education: "Education",
  교육: "Education",
  education: "Education",
  "All contents": "AllContents",
  Allcontents: "AllContents",
  "모든 콘텐츠": "AllContents",
  "모든 컨텐츠": "AllContents",
  모든콘텐츠: "AllContents",
  모든컨텐츠: "AllContents",
  "All Contents": "AllContents",
  AllContents: "AllContents",
  "all contents": "AllContents",
  안녕하세요: "Greetings",
  안녕: "Greetings",
  hi: "Greetings",
  hello: "Greetings",
  Hi: "Greetings",
  Hello: "Greetings",
  Resume: "ResumeLink",
  resume: "ResumeLink",
  이력서: "ResumeLink",
  Careers: "Careers",
  careers: "Careers",
  커리어: "Careers",
};
